import firebase from "firebase/app";
import "firebase/auth";
import { login as trackLogin, sign_up as trackSignup } from "../services/gtag";

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  // measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
};

// not required as next-firebase-auth will init
/* if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
} */

export const setFirebaseLanguage = (lang: string) => {
  let firebaseLang = lang;
  if (lang === "zh") firebaseLang = "zh-Hant";
  else if (lang === "cn") firebaseLang = "zh-Hans";
  firebase.auth().languageCode = firebaseLang;
};

export function signIn(username: string, password: string) {
  trackLogin("email");
  return firebase.auth().signInWithEmailAndPassword(username, password);
}

export function signUp(username: string, password: string) {
  trackSignup("email");
  return firebase.auth().createUserWithEmailAndPassword(username, password);
}

export function signOut() {
  return firebase.auth().signOut();
}

export function resetPassword(email: string) {
  return firebase.auth().sendPasswordResetEmail(email);
}

export function updatePassword(password: string) {
  return firebase.auth().currentUser.updatePassword(password);
}

export function reauthenticateWithCredential(password: string) {
  const user = firebase.auth().currentUser;
  const credential = firebase.auth.EmailAuthProvider.credential(
    user.email,
    password
  );
  return firebase.auth().currentUser.reauthenticateWithCredential(credential);
}

export default firebase; // TODO remove this
