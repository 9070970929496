/* eslint-disable react/forbid-prop-types */
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import Email from "@material-ui/icons/Email";
import LockIcon from "@material-ui/icons/Lock";
import Alert from "@material-ui/lab/Alert";
import { AuthAction, withAuthUser } from "next-firebase-auth";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { FormEventHandler, useEffect } from "react";
import errorPageStyle from "../../assets/jss/nextjs-material-kit-pro/pages/signupPageStyle";
import Card from "../../components/cardV2/Card";
import CardBody from "../../components/cardV2/CardBody";
import Button from "../../components/CustomButtons/Button";
import CustomInput from "../../components/CustomInput/CustomInput";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import InfoArea from "../../components/InfoArea/InfoArea";
import Layout from "../../components/layout";
import { setFirebaseLanguage, signIn } from "../../services/firebase";
import { PageProps } from "../../types/page";
import { getBuildVersion } from "../../utilities/common";

export async function getStaticProps(ctx) {
  const { locale } = ctx;
  const ssrConfig = await serverSideTranslations(locale, ["common", "login"]);
  const buildVersion = getBuildVersion();
  return {
    props: {
      ...ssrConfig,
      buildVersion,
    },
  };
}

const useStyles = makeStyles(errorPageStyle as any);

function LoginPage({ buildVersion }: PageProps) {
  const {
    t,
    i18n: { language },
  } = useTranslation("common");
  useEffect(() => {
    setFirebaseLanguage(language);
  }, [language]);
  const title = `${t("login:sectionTitle.login")}`;
  const classes = useStyles();
  const [errorMsg, setErrorMsg] = React.useState<string>("");
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const router = useRouter();
  const onSubmit: FormEventHandler<HTMLFormElement> = React.useCallback(
    (event) => {
      event.preventDefault();
      const username = event.target[0].value;
      const password = event.target[1].value;
      setIsLoading(true);

      signIn(username, password)
        .then(() => {
          setIsLoading(false);
          router.push(`/`);
        })
        .catch((err) => {
          console.error(err);
          setErrorMsg(t(`login:message.${err.code}`));
          setIsLoading(false);
        });
    },
    [language]
  );
  return (
    <Layout
      buildVersion={buildVersion}
      title={`${title} | Playwhat`}
      isHomepage
    >
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(/images/background/bg8.jpg)",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={10} md={10}>
              <Card className={classes.cardSignup}>
                <h2 className={classes.cardTitle}>{title}</h2>
                <CardBody>
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={5} md={5}>
                      <InfoArea
                        className={classes.infoArea}
                        title={t("login:fieldLabel.forgetPassword")}
                        description={
                          <Link href="/users/forget-password">
                            <a className="forget-password-link">
                              {t("login:message.forgetPassword")}
                            </a>
                          </Link>
                        }
                        icon={LockIcon}
                        iconColor="primary"
                      />
                      <InfoArea
                        className={classes.infoArea}
                        title={t("login:fieldLabel.forgetEmail")}
                        description={t("login:message.systemUpgradeMessage")}
                        icon={AnnouncementIcon}
                        iconColor="info"
                      />
                    </GridItem>
                    <GridItem xs={12} sm={5} md={5}>
                      {errorMsg && <Alert severity="error">{errorMsg}</Alert>}
                      <form className={classes.form} onSubmit={onSubmit}>
                        <CustomInput
                          id="email"
                          formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses,
                          }}
                          inputProps={{
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                className={classes.inputAdornment}
                              >
                                <Email className={classes.inputAdornmentIcon} />
                              </InputAdornment>
                            ),
                            placeholder: t("login:fieldLabel.username"),
                          }}
                        />
                        <CustomInput
                          id="password"
                          formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses,
                          }}
                          inputProps={{
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                className={classes.inputAdornment}
                              >
                                <LockIcon
                                  className={classes.inputAdornmentIcon}
                                />
                              </InputAdornment>
                            ),
                            placeholder: t("login:fieldLabel.password"),
                            type: "password",
                          }}
                        />
                        <div className={classes.textCenter}>
                          <Button
                            round
                            color="primary"
                            disabled={isLoading}
                            type="submit"
                          >
                            {t("login:fieldLabel.login")}
                          </Button>
                        </div>
                      </form>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
        {/* <div className={classes.creditTo}>
            Credit to{" "}
            <a
              href="https://snappy.collaction.hk/"
              rel="nofollow noreferrer"
              target="_blank"
              className={classes.creditToLink}
            >
              Snappy
            </a>
          </div> */}
      </div>
    </Layout>
  );
}

export default withAuthUser<PageProps>({
  whenAuthed: AuthAction.REDIRECT_TO_APP,
  whenAuthedBeforeRedirect: AuthAction.RETURN_NULL,
  whenUnauthedBeforeInit: AuthAction.SHOW_LOADER,
  whenUnauthedAfterInit: AuthAction.RENDER,
})(LoginPage);
