import {
  primaryColor,
  grayColor,
  container,
  cardTitle,
  whiteColor,
  blackColor,
  hexToRgb
} from "../../nextjs-material-kit-pro";

import customCheckboxRadioSwitchStyle from "../customCheckboxRadioSwitchStyle";

const signupPageStyle = {
  container: {
    ...container,
    zIndex: "2",
    position: "relative",
    paddingTop: "20vh",
    color: whiteColor
  },
  pageHeader: {
    minHeight: "100vh",
    height: "auto",
    display: "inherit",
    position: "relative",
    margin: "0",
    padding: "0",
    border: "0",
    alignItems: "center",
    "&:before": {
      background: `rgba(${hexToRgb(blackColor)}, 0.5)`
    },
    "&:after": {
      background:
        `linear-gradient(60deg,rgba(${hexToRgb(primaryColor[4])
        },.56),rgba(${hexToRgb(primaryColor[5])
        },.95))`
    },
    "&:before,&:after": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: '""'
    }
  },
  cardSignup: {
    borderRadius: "6px",
    boxShadow:
      `0 16px 24px 2px rgba(${hexToRgb(blackColor)
      }, 0.14), 0 6px 30px 5px rgba(${hexToRgb(blackColor)
      }, 0.12), 0 8px 10px -5px rgba(${hexToRgb(blackColor)
      }, 0.2);`,
    marginBottom: "100px",
    padding: "40px 0px"
  },
  cardTitle: {
    ...cardTitle,
    textDecoration: "none",
    textAlign: "center !important",
    marginBottom: "0.75rem"
  },
  ...customCheckboxRadioSwitchStyle,
  socials: {
    marginTop: "0",
    position: "absolute",
    width: "100%",
    transform: "none",
    left: "0",
    top: "0",
    height: "100%",
    lineHeight: "41px",
    fontSize: "20px"
  },
  textCenter: {
    textAlign: "center"
  },
  inputAdornment: {
    marginRight: "18px",
    position: "relative"
  },
  inputAdornmentIcon: {
    color: grayColor[13]
  },
  form: {
    margin: "0"
  },
  infoArea: {
    padding: "0px 0px 20px !important",
    fontSize: '1.2rem',
    lineHeight: 1.2,
    color: 'black'
  },
  block: {
    color: "inherit",
    padding: "0.9375rem",
    fontWeight: "500",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block"
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto"
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0"
  },
  left: {
    float: "left!important",
    display: "block",
    "&,& *,& *:hover,& *:focus": {
      color: `${whiteColor}  !important`
    }
  },
  right: {
    padding: "15px 0",
    margin: "0",
    float: "right",
    "&,& *,& *:hover,& *:focus": {
      color: `${whiteColor}  !important`
    }
  },
  icon: {
    width: "18px",
    height: "18px",
    top: "3px",
    position: "relative"
  },
  creditTo: {
    position: 'absolute',
    right: '2px',
    bottom: '2px',
    padding: '2px',
    fontSize: '8px',
    color: 'white',
    zIndex: 99,
    opacity: 0.3,
    lineHeight: 1.8,
  },
  creditToLink: {
    color: 'white !important',
  }
};

export default signupPageStyle;
